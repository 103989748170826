import React from 'react';
import * as Ink from '@carta/ink';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Header from '../components/Header/Header';
import PreviewCard from '../components/Common/PreviewCard';

const TypographyComponents = ({
  data: {
    typographyComponents: { nodes },
  },
}) => {
  return (
    <Layout pageTitle="Typography Components">
      <Header title="Typography" />
      <Ink.Tiles columns={[1, 1, 2, 2, 3]}>
        {nodes.map(component => (
          <PreviewCard
            title={component.displayName}
            text={component.purpose}
            actionLabel="View details"
            actionHref={`/components/${component.displayName}`}
            previewWithCheckerboard
            previewCode={component.samples && component.samples.length > 0 ? component.samples[0].code : '<></>'}
          />
        ))}
      </Ink.Tiles>
    </Layout>
  );
};

export default TypographyComponents;

export const query = graphql`
  query TypographyComponentsQuery {
    typographyComponents: allComponentsJson(filter: { categories: { in: "typography" } }, sort: { fields: position }) {
      nodes {
        ...ComponentFields
      }
    }
  }
`;
